<template>
  <div>
    <b-modal
      ref="modalDatadDetail"
      :title="$t('Detalhe do toque de comunicação')"
      size="lg"
      body-class=""
      :visible="false"
      :ok-only="true"
      :ok-title="$t('Fechar')"
      ok-variant="primary"
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
      @ok="confirmSaveModal"
    >
      <b-row v-if="typeEmail === true">
        <b-col md="12">
          <div class="mt-1 mb-sm-0">
            <span class="text-muted mb-50 d-block">{{ $t('Data do processamento de envio') }}</span>
            <span>{{ report.data_injection }}</span>
          </div>
        </b-col>
        <b-col md="12">
          <div class="mt-1 mb-sm-0">
            <span class="text-muted mb-50 d-block">{{ $t('Data de envio') }}</span>
            <span>{{ report.data_delivery }}</span>
          </div>
        </b-col>
        <b-col md="12">
          <div class="mt-1 mb-sm-0">
            <span class="text-muted mb-50 d-block">{{ $t('Data em que foi aberto o email') }}</span>
            <span>{{ report.data_open }}</span>
          </div>
        </b-col>
        <b-col md="12">
          <div class="mt-1 mb-sm-0">
            <span class="text-muted mb-50 d-block">{{ $t('Data do clique no email') }}</span>
            <span>{{ report.data_click }}</span>
          </div>
        </b-col>
        <b-col md="12">
          <div class="mt-1 mb-sm-0">
            <span class="text-muted mb-50 d-block">{{ $t('Motivo da falha de envio') }}</span>
            <span>{{ report.mgs_error }}</span>
          </div>
        </b-col>
      </b-row>

      <b-row v-if="typeEmail === false">
        <b-col md="12">
          <div class="mt-1 mb-sm-0">
            <span class="text-muted mb-50 d-block">{{ $t('Data de envio') }}</span>
            <span>{{ report.data_envio }}</span>
          </div>
        </b-col>
        <b-col md="12">
          <div class="mt-1 mb-sm-0">
            <span class="text-muted mb-50 d-block">{{ $t('Estado') }}</span>
            <span>{{ report.Estado }}</span>
          </div>
        </b-col>
      </b-row>

    </b-modal>
  </div>
</template>

<script>
import {
  BModal, BRow, BCol,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import useAppConfig from '@core/app-config/useAppConfig'
import { mixFormGeral } from '../../mixins'

export default {
  components: {
    BModal,
    BRow,
    BCol,
  },
  directives: {
    Ripple,
  },
  mixins: [mixFormGeral],
  props: {
    report: {
      type: Array,
      default: () => {},
    },
    typeEmail: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
    }
  },
  computed: {
    isDark() {
      return this.skin.value === 'dark'
    },
  },
  methods: {
    showModal() {
      return new Promise((resolve, reject) => {
        this.$refs.modalDatadDetail.show()
        this.resolvePromise = resolve
        this.rejectPromise = reject
      })
    },
    hideModal() {
      this.$refs.modalDatadDetail.hide()
    },
    async confirmSaveModal(evt) {
      evt.preventDefault()
      this.resolvePromise(true)
    },
  },
  setup() {
    const { skin } = useAppConfig()

    return {
      skin,
    }
  },
}
</script>
